<template>
  <div :data-shopperraction="shopper_reaction">
    <h3 class="mb-4">
      {{ $t("SHOPPER_REACTION_HEADLINE") }}
    </h3>

    <div class="mb-4">
      <p class="sublines">
        {{ $t("SHOPPER_REACTION_CONFIRM_PROCESS") }}
      </p>
      <Switch
        :initValue="shopper_reaction.confirmProcess"
        :disabled="activeWaveReadonly"
        v-on:changed="(checked) => setShopperReaction('confirmProcess', checked)"
      />
    </div>
    <div>
      <a
        class="text-reset download-btn"
        @click="getShopperReactionFile()"
      >
        <i class="bi bi-file-earmark-ppt-fill"></i>
        <span>{{ $t("SHOPPER_REACTION_DOWNLOAD_PRESENTATION") }}</span>
      </a>
    </div>
    <div class="mt-3" v-show="!shopper_reaction.confirmProcess">
      <label class="pb-2" for="comment-textarea">{{
        $t("NO_AGREE_PLEASE_COMMENT")
      }}</label>
      <textarea
        class="w-100 form-control"
        id="comment-textarea"
        v-model="shopper_reaction.confirmProcessComment"
        :readonly="shopper_reaction.confirmProcess || activeWaveReadonly"
        @change="setShopperReaction('confirmProcessComment',shopper_reaction.confirmProcessComment)"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);

export default {
  name: "ShopperReaction",
  components: { Switch },
  methods: {
    setShopperReaction(key, value) {
      let shopper_reaction = JSON.parse(JSON.stringify(this.shopper_reaction));
      shopper_reaction[key] = value;
      this.$store.dispatch("waves/setShopperReaction", shopper_reaction);
    },
    async getShopperReactionFile() {
      await this.$store.dispatch("downloads/downloadFile", "shopper_reaction");
    },
  },
  computed: {
    shopper_reaction() {
      return this.$store.getters["waves/getShopperReaction"];
    },
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
};
</script>

<style lang="scss" scoped>
.download-link {
  color: black;
  text-decoration: none;

  span {
    font-weight: bold;
    font-size: 17px;
    margin-left: 5px;
  }

  .bi {
    font-size: 23px;
  }
}
.download-btn {
  display: block; 
  cursor: pointer; 
  width: fit-content;
  color: black;
  text-decoration: none;
}
.download-btn:hover {
  text-decoration: underline;
}
</style>
